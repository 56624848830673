@import "reset";

$white: rgba(255, 255, 255, 1);

#app {
  font-family: "Raleway", sans-serif;
  #primary-section {
    display: grid;
    grid: 64px minmax(calc(100vh - 64px), auto) / auto;
    header {
      height: 64px;
      line-height: 64px;
      text-align: center;
      -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
        0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
      box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
        0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
      @include tablet-landscape-up {
        padding: 0 24px;
        text-align: left;
      }
      @include desktop-up {
        padding: 0 256px;
        text-align: left;
      }
      span {
        font-family: "Rubik", sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 1.25rem;
        &:first-child {
          color: #3a3a3a;
          span:first-child {
            color: dodgerblue;
          }
        }
      }
      ul {
        display: none;
        @include tablet-landscape-up {
          display: block;
          float: right;
          list-style: none;
          text-align: right;
          li {
            display: inline-block;
            padding: 0 24px;
            font-weight: 700;
            cursor: pointer;
            text-transform: uppercase;
            font-size: 0.9em;
            &:hover {
              background: #eee;
            }
          }
        }
      }
    }
    .portback {
      position: relative;
      // background: url("../img/portback.jpeg")
      //   no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: rgb(14, 123, 231);
        opacity: 0.85;
        z-index: 1;
      }
      .center {
        font-family: "Rubik", sans-serif;
        position: absolute;
        width: 90%;
        text-align: center;
        color: $white;
        z-index: 9;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        h3 {
          font-weight: 400;
          font-size: 1em;
          @include tablet-portrait-up {
            font-size: 1.5em;
          }
        }
        h1 {
          font-weight: 700;
          font-size: 1.5em;
          margin: 4px 0 6px 0;
          @include tablet-portrait-up {
            font-size: 3.5em;
            margin: 6px 0 16px 0;
          }
        }
        h4 {
          font-weight: 400;
          font-size: 0.95em;
          @include tablet-portrait-up {
            font-size: 1.2em;
          }
        }
        img {
          display: none;
          margin-top: 64px;
          cursor: pointer;
          @include tablet-portrait-up {
            display: inline-block;
            width: 48px;
            height: 48px;
            margin-top: 80px;
            margin-bottom: -80px;
            margin-left: auto;
            margin-right: auto;
          }
          @include desktop-up {
            margin-top: 40px;
            margin-bottom: -40px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
  section {
    h2 {
      text-align: center;
      font-size: 1.75em;
    }
    &.fs {
      min-height: 100vh;
    }
    &#about-section {
      padding: 128px 24px;
      h2 {
        text-align: center;
        margin-bottom: 20px;
      }
      p {
        margin: 0 auto;
        text-align: center;
        @include tablet-landscape-up {
          max-width: 60%;
        }
      }
    }
    &#things-section {
      padding: 64px 24px;
      background: #1485e0;
      h2 {
        color: rgba(255, 255, 255, 1);
      }
      h5 {
        color: rgba(255, 255, 255, 0.64);
        text-align: center;
        font-weight: 300;
        margin: 10px auto 40px auto;
        line-height: 1.1;
      }
      p {
        max-width: 80%;
        margin: 0 auto;
        text-align: center;
      }
      @include desktop-up {
        .container {
          max-width: 1170px;
          margin: auto;
        }
      }
      .grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        @include tablet-landscape-up {
          grid-template-columns: repeat(2, 1fr);
          grid-auto-rows: 1fr;
          gap: 40px;
        }
        .item {
          text-align: center;
          padding-bottom: 20px;
          img {
            margin: 12px 0 18px 0;
            height: 128px;
          }
          p {
            color: rgba(255, 255, 255, 0.85);
            max-width: 75%;
            margin: auto;
            margin-bottom: 20px;
            font-weight: 300;
            font-size: 14px;
            line-height: 1.5;
            b {
              font-weight: 700;
            }
          }
        }
      }
    }
    &#skills-section {
      padding: 64px 24px 32px 24px;
      h2 {
        color: rgba(0, 0, 0, 0.95);
        margin: 0 auto 10px auto;
      }
      h5 {
        color: rgba(0, 0, 0, 0.64);
        text-align: center;
        font-weight: 300;
        margin: 10px auto 20px auto;
        line-height: 1.1;
      }
      @include desktop-up {
        .container {
          max-width: 1170px;
          margin: auto;
        }
      }
      .grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        @include tablet-portrait-up {
          grid-template-columns: repeat(2, 1fr);
          grid-auto-rows: 1fr;
        }
        @include tablet-landscape-up {
          grid-template-columns: repeat(3, 1fr);
        }
        .item {
          text-align: center;
          img {
            margin: 12px 0 18px 0;
            height: 128px;
          }
          p {
            color: rgba(0, 0, 0, 0.65);
            max-width: 75%;
            margin: auto;
            margin-bottom: 20px;
            font-weight: 300;
            font-size: 14px;
            line-height: 1.5;
            b {
              font-weight: 700;
            }
          }
        }
      }
    }
    &#projects-section {
      padding: 64px 24px 32px 24px;
      h2 {
        color: rgba(0, 0, 0, 0.95);
        margin: 0 auto 10px auto;
      }
      h5 {
        color: rgba(0, 0, 0, 0.64);
        text-align: center;
        font-weight: 300;
        margin: 10px auto 20px auto;
        line-height: 1.1;
      }
      .btn-container {
        text-align: center;
        max-width: 80%;
        margin: 0 auto 48px auto;
        .btn {
          padding: 8px 12px;
          margin: 4px;
          background: #1485e0;
          color: white;
          font-weight: 700;
          text-transform: uppercase;
          display: inline-block;
          font-size: 0.85em;
          border-radius: 3px;
          cursor: pointer;
          &:hover {
            background: #5ca4e0;
          }
          &.disabled {
            background: #5ca4e0;
            &:hover {
              cursor: default;
            }
          }
        }
      }
      @include desktop-up {
        .container {
          max-width: 1170px;
          margin: auto;
        }
      }
      .grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        @include tablet-portrait-up {
          grid-template-columns: repeat(2, 1fr);
          grid-auto-rows: 1fr;
        }
        @include tablet-landscape-up {
          grid-template-columns: repeat(3, 1fr);
        }
        .item {
          text-align: center;
          padding: 24px 12px;
          img {
            margin: 12px 0 18px 0;
            width: 128px;
          }
          p {
            color: rgba(0, 0, 0, 0.65);
            max-width: 75%;
            margin: auto;
            margin-bottom: 20px;
            font-weight: 300;
            font-size: 14px;
            line-height: 1.5;
            b {
              font-weight: 700;
            }
          }
          .tags {
            padding: 0 32px;
            .tag {
              padding: 4px 6px;
              margin: 4px;
              color: #fff;
              text-transform: uppercase;
              display: inline-block;
              font-size: 0.75em;
              font-weight: bold;
              border-radius: 3px;
              &.tech {
                background: #60a2d8;
              }
              &.status {
                background: #61d183;
              }
              &.ref {
                background: #77d6ee;
              }
            }
          }
        }
      }
    }
  }
}
