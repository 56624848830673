::selection {
  text-shadow: none;
  background: #32b5cc71;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

.browserupgrade {
  color: #000;
  background: #ccc;
  margin: .2em 0;
  padding: .2em 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

figcaption, figure, main {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details, menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

[invisible] {
  visibility: hidden;
}

#app {
  font-family: Raleway, sans-serif;
}

#app #primary-section {
  grid: 64px minmax(calc(100vh - 64px), auto) / auto;
  display: grid;
}

#app #primary-section header {
  height: 64px;
  text-align: center;
  line-height: 64px;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px -1px #0000004d;
}

@media (min-width: 900px) {
  #app #primary-section header {
    text-align: left;
    padding: 0 24px;
  }
}

@media (min-width: 1199px) {
  #app #primary-section header {
    text-align: left;
    padding: 0 256px;
  }
}

#app #primary-section header span {
  text-transform: uppercase;
  font-family: Rubik, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
}

#app #primary-section header span:first-child {
  color: #3a3a3a;
}

#app #primary-section header span:first-child span:first-child {
  color: #1e90ff;
}

#app #primary-section header ul {
  display: none;
}

@media (min-width: 900px) {
  #app #primary-section header ul {
    float: right;
    text-align: right;
    list-style: none;
    display: block;
  }

  #app #primary-section header ul li {
    cursor: pointer;
    text-transform: uppercase;
    padding: 0 24px;
    font-size: .9em;
    font-weight: 700;
    display: inline-block;
  }

  #app #primary-section header ul li:hover {
    background: #eee;
  }
}

#app #primary-section .portback {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
}

#app #primary-section .portback .overlay {
  height: 100%;
  width: 100%;
  opacity: .85;
  z-index: 1;
  background: #0e7be7;
  position: absolute;
  top: 0;
  left: 0;
}

#app #primary-section .portback .center {
  width: 90%;
  text-align: center;
  color: #fff;
  z-index: 9;
  text-transform: uppercase;
  font-family: Rubik, sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#app #primary-section .portback .center h3 {
  font-size: 1em;
  font-weight: 400;
}

@media (min-width: 600px) {
  #app #primary-section .portback .center h3 {
    font-size: 1.5em;
  }
}

#app #primary-section .portback .center h1 {
  margin: 4px 0 6px;
  font-size: 1.5em;
  font-weight: 700;
}

@media (min-width: 600px) {
  #app #primary-section .portback .center h1 {
    margin: 6px 0 16px;
    font-size: 3.5em;
  }
}

#app #primary-section .portback .center h4 {
  font-size: .95em;
  font-weight: 400;
}

@media (min-width: 600px) {
  #app #primary-section .portback .center h4 {
    font-size: 1.2em;
  }
}

#app #primary-section .portback .center img {
  cursor: pointer;
  margin-top: 64px;
  display: none;
}

@media (min-width: 600px) {
  #app #primary-section .portback .center img {
    width: 48px;
    height: 48px;
    margin: 80px auto -80px;
    display: inline-block;
  }
}

@media (min-width: 1199px) {
  #app #primary-section .portback .center img {
    margin: 40px auto -40px;
  }
}

#app section h2 {
  text-align: center;
  font-size: 1.75em;
}

#app section.fs {
  min-height: 100vh;
}

#app section#about-section {
  padding: 128px 24px;
}

#app section#about-section h2 {
  text-align: center;
  margin-bottom: 20px;
}

#app section#about-section p {
  text-align: center;
  margin: 0 auto;
}

@media (min-width: 900px) {
  #app section#about-section p {
    max-width: 60%;
  }
}

#app section#things-section {
  background: #1485e0;
  padding: 64px 24px;
}

#app section#things-section h2 {
  color: #fff;
}

#app section#things-section h5 {
  color: #ffffffa3;
  text-align: center;
  margin: 10px auto 40px;
  font-weight: 300;
  line-height: 1.1;
}

#app section#things-section p {
  max-width: 80%;
  text-align: center;
  margin: 0 auto;
}

@media (min-width: 1199px) {
  #app section#things-section .container {
    max-width: 1170px;
    margin: auto;
  }
}

#app section#things-section .grid {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  display: grid;
}

@media (min-width: 900px) {
  #app section#things-section .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    gap: 40px;
  }
}

#app section#things-section .grid .item {
  text-align: center;
  padding-bottom: 20px;
}

#app section#things-section .grid .item img {
  height: 128px;
  margin: 12px 0 18px;
}

#app section#things-section .grid .item p {
  color: #ffffffd9;
  max-width: 75%;
  margin: auto auto 20px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
}

#app section#things-section .grid .item p b {
  font-weight: 700;
}

#app section#skills-section {
  padding: 64px 24px 32px;
}

#app section#skills-section h2 {
  color: #000000f2;
  margin: 0 auto 10px;
}

#app section#skills-section h5 {
  color: #000000a3;
  text-align: center;
  margin: 10px auto 20px;
  font-weight: 300;
  line-height: 1.1;
}

@media (min-width: 1199px) {
  #app section#skills-section .container {
    max-width: 1170px;
    margin: auto;
  }
}

#app section#skills-section .grid {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  display: grid;
}

@media (min-width: 600px) {
  #app section#skills-section .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
  }
}

@media (min-width: 900px) {
  #app section#skills-section .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

#app section#skills-section .grid .item {
  text-align: center;
}

#app section#skills-section .grid .item img {
  height: 128px;
  margin: 12px 0 18px;
}

#app section#skills-section .grid .item p {
  color: #000000a6;
  max-width: 75%;
  margin: auto auto 20px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
}

#app section#skills-section .grid .item p b {
  font-weight: 700;
}

#app section#projects-section {
  padding: 64px 24px 32px;
}

#app section#projects-section h2 {
  color: #000000f2;
  margin: 0 auto 10px;
}

#app section#projects-section h5 {
  color: #000000a3;
  text-align: center;
  margin: 10px auto 20px;
  font-weight: 300;
  line-height: 1.1;
}

#app section#projects-section .btn-container {
  text-align: center;
  max-width: 80%;
  margin: 0 auto 48px;
}

#app section#projects-section .btn-container .btn {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  background: #1485e0;
  border-radius: 3px;
  margin: 4px;
  padding: 8px 12px;
  font-size: .85em;
  font-weight: 700;
  display: inline-block;
}

#app section#projects-section .btn-container .btn:hover, #app section#projects-section .btn-container .btn.disabled {
  background: #5ca4e0;
}

#app section#projects-section .btn-container .btn.disabled:hover {
  cursor: default;
}

@media (min-width: 1199px) {
  #app section#projects-section .container {
    max-width: 1170px;
    margin: auto;
  }
}

#app section#projects-section .grid {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  display: grid;
}

@media (min-width: 600px) {
  #app section#projects-section .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
  }
}

@media (min-width: 900px) {
  #app section#projects-section .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

#app section#projects-section .grid .item {
  text-align: center;
  padding: 24px 12px;
}

#app section#projects-section .grid .item img {
  width: 128px;
  margin: 12px 0 18px;
}

#app section#projects-section .grid .item p {
  color: #000000a6;
  max-width: 75%;
  margin: auto auto 20px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
}

#app section#projects-section .grid .item p b {
  font-weight: 700;
}

#app section#projects-section .grid .item .tags {
  padding: 0 32px;
}

#app section#projects-section .grid .item .tags .tag {
  color: #fff;
  text-transform: uppercase;
  border-radius: 3px;
  margin: 4px;
  padding: 4px 6px;
  font-size: .75em;
  font-weight: bold;
  display: inline-block;
}

#app section#projects-section .grid .item .tags .tag.tech {
  background: #60a2d8;
}

#app section#projects-section .grid .item .tags .tag.status {
  background: #61d183;
}

#app section#projects-section .grid .item .tags .tag.ref {
  background: #77d6ee;
}

/*# sourceMappingURL=index.01108751.css.map */
